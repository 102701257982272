import { env } from '@/utils/env'
import { SelectedCoinDenom } from '@/wallet-utils/types'
import { Bech32Address } from '@keplr-wallet/cosmos'
import { ChainInfo, Currency } from '@keplr-wallet/types'
import { IBCX_CURRENCY, STAKED_IBCX_CURRENCY } from './ibcx'

export const STRIDE_CURRENCY: Currency = {
  coinDenom: 'STRD',
  coinMinimalDenom: 'ustrd',
  coinDecimals: 6
}

export const STRIDE_EXTRA_CURRENCIES: Currency[] = [
  {
    coinDenom: 'ATOM',
    coinMinimalDenom: 'uatom',
    coinDecimals: 6
  },
  {
    coinDenom: 'JUNO',
    coinMinimalDenom: 'ujuno',
    coinDecimals: 6
  },
  {
    coinDenom: 'SCRT',
    coinMinimalDenom: 'uscrt',
    coinDecimals: 6
  }
]

export const STRIDE_CHAIN_INFO: ChainInfo = {
  rpc: env('STRIDE_RPC', 'https://edge.stride.zone/api/stride/rpc'),
  rest: env('STRIDE_REST', 'https://edge.stride.zone/api/stride/lcd'),
  chainId: env('STRIDE_CHAIN_ID', 'stride-1'),
  chainName: env('STRIDE_CHAIN_NAME', 'Stride'),
  stakeCurrency: STRIDE_CURRENCY,
  bip44: {
    coinType: 118
  },
  bech32Config: {
    bech32PrefixAccAddr: 'stride',
    bech32PrefixAccPub: 'stridepub',
    bech32PrefixValAddr: 'stridevaloper',
    bech32PrefixValPub: 'stridevaloperpub',
    bech32PrefixConsAddr: 'stridevalcons',
    bech32PrefixConsPub: 'stridevalconspub'
  },
  currencies: [STRIDE_CURRENCY, ...STRIDE_EXTRA_CURRENCIES],
  feeCurrencies: [STRIDE_CURRENCY, ...STRIDE_EXTRA_CURRENCIES],
  coinType: 118,
  features: ['stargate', 'ibc-transfer', 'ibc-go']
}

export const GAIA_CHAIN_INFO: ChainInfo = {
  rpc: env('GAIA_RPC', 'https://edge.stride.zone/api/cosmos/rpc'),
  rest: env('GAIA_REST', 'https://edge.stride.zone/api/cosmos/lcd'),
  chainId: env('GAIA_CHAIN_ID', 'cosmoshub-4'),
  chainName: env('GAIA_CHAIN_NAME', 'Cosmos Hub'),
  bip44: {
    coinType: 118
  },
  bech32Config: Bech32Address.defaultBech32Config('cosmos'),
  stakeCurrency: {
    coinDenom: 'ATOM',
    coinMinimalDenom: 'uatom',
    coinDecimals: 6,
    coinGeckoId: 'cosmos'
    // coinImageUrl: '/tokens/atom.svg'
  },
  currencies: [
    {
      coinDenom: 'ATOM',
      coinMinimalDenom: 'uatom',
      coinDecimals: 6,
      coinGeckoId: 'cosmos'
      // coinImageUrl: '/tokens/atom.svg'
    }
  ],
  feeCurrencies: [
    {
      coinDenom: 'ATOM',
      coinMinimalDenom: 'uatom',
      coinDecimals: 6,
      coinGeckoId: 'cosmos'
      // coinImageUrl: '/tokens/atom.svg'
    }
  ],
  features: ['stargate', 'ibc-transfer', 'no-legacy-stdTx', 'ibc-go']
}

export const OSMO_CHAIN_INFO: ChainInfo = {
  rpc: env('OSMO_RPC', 'https://edge.stride.zone/api/osmosis/rpc'),
  rest: env('OSMO_REST', 'https://edge.stride.zone/api/osmosis/lcd'),
  chainId: env('OSMO_CHAIN_ID', 'osmosis-1'),
  chainName: env('OSMO_CHAIN_NAME', 'Osmosis'),
  bip44: {
    coinType: 118
  },
  bech32Config: Bech32Address.defaultBech32Config('osmo'),
  stakeCurrency: {
    coinDenom: 'OSMO',
    coinMinimalDenom: 'uosmo',
    coinDecimals: 6,
    coinGeckoId: 'osmosis'
    // coinImageUrl: '/tokens/atom.svg'
  },
  currencies: [
    {
      coinDenom: 'OSMO',
      coinMinimalDenom: 'uosmo',
      coinDecimals: 6,
      coinGeckoId: 'osmosis'
      // coinImageUrl: '/tokens/atom.svg'
    },
    IBCX_CURRENCY,
    STAKED_IBCX_CURRENCY
  ],
  feeCurrencies: [
    {
      coinDenom: 'OSMO',
      coinMinimalDenom: 'uosmo',
      coinDecimals: 6,
      coinGeckoId: 'osmosis'
      // coinImageUrl: '/tokens/atom.svg'
    }
  ],
  features: ['stargate', 'ibc-transfer', 'no-legacy-stdTx', 'ibc-go']
}

export const STARS_CHAIN_INFO: ChainInfo = {
  rpc: env('STARS_RPC', 'https://edge.stride.zone/api/stargaze/rpc'),
  rest: env('STARS_REST', 'https://edge.stride.zone/api/stargaze/lcd'),
  chainId: env('STARS_CHAIN_ID', 'stargaze-1'),
  chainName: env('STARS_CHAIN_NAME', 'Stargaze'),
  bip44: {
    coinType: 118
  },
  bech32Config: Bech32Address.defaultBech32Config('stars'),
  stakeCurrency: {
    coinDenom: 'STARS',
    coinMinimalDenom: 'ustars',
    coinDecimals: 6,
    coinGeckoId: 'stargaze'
    // coinImageUrl: '/tokens/atom.svg'
  },
  currencies: [
    {
      coinDenom: 'STARS',
      coinMinimalDenom: 'ustars',
      coinDecimals: 6,
      coinGeckoId: 'stargaze'
      // coinImageUrl: '/tokens/atom.svg'
    }
  ],
  feeCurrencies: [
    {
      coinDenom: 'STARS',
      coinMinimalDenom: 'ustars',
      coinDecimals: 6,
      coinGeckoId: 'stargaze'
      // coinImageUrl: '/tokens/atom.svg'
    }
  ]
}

export const SCRT_CHAIN_INFO: ChainInfo = {
  rpc: env('SCRT_RPC', 'https://rpc-secret.keplr.app'),
  rest: env('SCRT_REST', 'https://lcd-secret.keplr.app'),
  chainId: env('SCRT_CHAIN_ID', 'secret-4'),
  chainName: env('SCRT_CHAIN_NAME', 'Secret Network'),
  bip44: {
    coinType: 529
  },
  bech32Config: Bech32Address.defaultBech32Config('secret'),
  stakeCurrency: {
    coinDenom: 'SCRT',
    coinMinimalDenom: 'uscrt',
    coinDecimals: 6,
    coinGeckoId: 'secret'
    // coinImageUrl: '/tokens/scrt.svg'
  },
  currencies: [
    {
      coinDenom: 'SCRT',
      coinMinimalDenom: 'uscrt',
      coinDecimals: 6,
      coinGeckoId: 'secret'
      // coinImageUrl: '/tokens/scrt.svg'
    }
  ],
  feeCurrencies: [
    {
      coinDenom: 'SCRT',
      coinMinimalDenom: 'uscrt',
      coinDecimals: 6,
      coinGeckoId: 'secret'
      // coinImageUrl: '/tokens/scrt.svg'
    }
  ],
  features: ['stargate', 'ibc-transfer', 'no-legacy-stdTx']
}

export const JUNO_CHAIN_INFO: ChainInfo = {
  rpc: env('JUNO_RPC', 'https://edge.stride.zone/api/juno/rpc'),
  rest: env('JUNO_REST', 'https://edge.stride.zone/api/juno/lcd'),
  chainId: env('JUNO_CHAIN_ID', 'juno-1'),
  chainName: env('JUNO_CHAIN_NAME', 'Juno'),
  bip44: {
    coinType: 118
  },
  bech32Config: Bech32Address.defaultBech32Config('juno'),
  stakeCurrency: {
    coinDenom: 'JUNO',
    coinMinimalDenom: 'ujuno',
    coinDecimals: 6,
    coinGeckoId: 'juno-network'
    // coinImageUrl: '/tokens/juno.svg'
  },
  currencies: [
    {
      coinDenom: 'JUNO',
      coinMinimalDenom: 'ujuno',
      coinDecimals: 6,
      coinGeckoId: 'juno-network'
      // coinImageUrl: '/tokens/juno.svg'
    }
  ],
  feeCurrencies: [
    {
      coinDenom: 'JUNO',
      coinMinimalDenom: 'ujuno',
      coinDecimals: 6,
      coinGeckoId: 'juno-network'
      // coinImageUrl: '/tokens/juno.svg'
    }
  ],
  features: ['stargate', 'ibc-transfer', 'ibc-go', 'no-legacy-stdTx', 'wasmd_0.24+', 'cosmwasm']
}

export const LUNA_CHAIN_INFO: ChainInfo = {
  rpc: env('LUNA_RPC', 'https://edge.stride.zone/api/terra2/rpc'),
  rest: env('LUNA_REST', 'https://edge.stride.zone/api/terra2/lcd'),
  chainId: env('LUNA_CHAIN_ID', 'phoenix-1'),
  chainName: env('LUNA_CHAIN_NAME', 'Terra'),
  bip44: {
    coinType: 330
  },
  bech32Config: Bech32Address.defaultBech32Config('terra'),
  stakeCurrency: {
    coinDenom: 'LUNA',
    coinMinimalDenom: 'uluna',
    coinDecimals: 6,
    coinGeckoId: 'terra-luna-2'
    // coinImageUrl: '/tokens/atom.svg'
  },
  currencies: [
    {
      coinDenom: 'LUNA',
      coinMinimalDenom: 'uluna',
      coinDecimals: 6,
      coinGeckoId: 'terra-luna-2'
      // coinImageUrl: '/tokens/atom.svg'
    }
  ],
  feeCurrencies: [
    {
      coinDenom: 'LUNA',
      coinMinimalDenom: 'uluna',
      coinDecimals: 6,
      coinGeckoId: 'terra-luna-2'
      // coinImageUrl: '/tokens/atom.svg'
    }
  ],
  features: ['stargate', 'ibc-transfer', 'no-legacy-stdTx', 'ibc-go']
}

export const EVMOS_CHAIN_INFO: ChainInfo = {
  rpc: env('EVMOS_RPC', 'https://edge.stride.zone/api/evmos/rpc'),
  rest: env('EVMOS_REST', 'https://edge.stride.zone/api/evmos/lcd'),
  chainId: env('EVMOS_CHAIN_ID', 'evmos_9001-2'),
  chainName: env('EVMOS_CHAIN_NAME', 'Evmos'),
  bip44: {
    coinType: 60
  },
  bech32Config: Bech32Address.defaultBech32Config('evmos'),
  stakeCurrency: {
    coinDenom: 'EVMOS',
    coinMinimalDenom: 'aevmos',
    coinDecimals: 18,
    coinGeckoId: 'evmos'
    // coinImageUrl: '/chains/EVMOS.svg'
  },
  currencies: [
    {
      coinDenom: 'EVMOS',
      coinMinimalDenom: 'aevmos',
      coinDecimals: 18,
      coinGeckoId: 'evmos'
      // coinImageUrl: '/chains/EVMOS.svg'
    }
  ],
  feeCurrencies: [
    {
      coinDenom: 'EVMOS',
      coinMinimalDenom: 'aevmos',
      coinDecimals: 18,
      coinGeckoId: 'evmos'
      // coinImageUrl: '/chains/EVMOS.svg'
    }
  ],
  features: ['ibc-transfer', 'ibc-go', 'eth-address-gen', 'eth-key-sign']
}

export const INJ_CHAIN_INFO: ChainInfo = {
  rpc: env('INJ_RPC', 'https://edge.stride.zone/api/injective/rpc'),
  rest: env('INJ_REST', 'https://edge.stride.zone/api/injective/lcd'),
  chainId: env('INJ_CHAIN_ID', 'injective-1'),
  chainName: env('INJ_CHAIN_NAME', 'Injective'),
  bip44: {
    coinType: 60
  },
  bech32Config: Bech32Address.defaultBech32Config('inj'),
  stakeCurrency: {
    coinDenom: 'INJ',
    coinMinimalDenom: 'inj',
    coinDecimals: 18,
    coinGeckoId: 'injective-protocol'
    // coinImageUrl: '/chains/INJ.svg'
  },
  currencies: [
    {
      coinDenom: 'INJ',
      coinMinimalDenom: 'inj',
      coinDecimals: 18,
      coinGeckoId: 'injective-protocol'
      // coinImageUrl: '/chains/INJ.svg'
    }
  ],
  feeCurrencies: [
    {
      coinDenom: 'INJ',
      coinMinimalDenom: 'inj',
      coinDecimals: 18,
      coinGeckoId: 'injective-protocol'
      // coinImageUrl: '/chains/INJ.svg'
    }
  ],
  features: ['ibc-transfer', 'ibc-go', 'eth-address-gen', 'eth-key-sign']
}

export const UMEE_CHAIN_INFO: ChainInfo = {
  rpc: env('UMEE_RPC', 'https://edge.stride.zone/api/umee/rpc'),
  rest: env('UMEE_REST', 'https://edge.stride.zone/api/umee/lcd'),
  chainId: env('UMEE_CHAIN_ID', 'umee-1'),
  chainName: env('UMEE_CHAIN_NAME', 'Umee'),
  bip44: {
    coinType: 118
  },
  bech32Config: Bech32Address.defaultBech32Config('umee'),
  stakeCurrency: {
    coinDenom: 'UMEE',
    coinMinimalDenom: 'uumee',
    coinDecimals: 6,
    coinGeckoId: 'umee'
    // coinImageUrl: '/chains/UMEE.svg'
  },
  currencies: [
    {
      coinDenom: 'UMEE',
      coinMinimalDenom: 'uumee',
      coinDecimals: 6,
      coinGeckoId: 'umee'
      // coinImageUrl: '/chains/UMEE.svg'
    }
  ],
  feeCurrencies: [
    {
      coinDenom: 'UMEE',
      coinMinimalDenom: 'uumee',
      coinDecimals: 6,
      coinGeckoId: 'umee'
      // coinImageUrl: '/chains/UMEE.svg'
    }
  ],
  features: []
}

export const CRE_CHAIN_INFO: ChainInfo = {
  rpc: env('CRE_RPC', 'https://edge.stride.zone/api/crescent/rpc'),
  rest: env('CRE_REST', 'https://edge.stride.zone/api/crescent/lcd'),
  chainId: env('CRE_CHAIN_ID', 'crescent-1'),
  chainName: env('CRE_CHAIN_NAME', 'Crescent'),
  bip44: {
    coinType: 118
  },
  bech32Config: Bech32Address.defaultBech32Config('cre'),
  stakeCurrency: {
    coinDenom: 'CRE',
    coinMinimalDenom: 'ucre',
    coinDecimals: 6,
    coinGeckoId: 'crescent-network'
    // coinImageUrl: '/chains/CRE.svg'
  },
  currencies: [
    {
      coinDenom: 'CRE',
      coinMinimalDenom: 'ucre',
      coinDecimals: 6,
      coinGeckoId: 'crescent-network'
      // coinImageUrl: '/chains/CRE.svg'
    }
  ],
  feeCurrencies: [
    {
      coinDenom: 'CRE',
      coinMinimalDenom: 'ucre',
      coinDecimals: 6,
      coinGeckoId: 'crescent-network'
      // coinImageUrl: '/chains/CRE.svg'
    }
  ],
  features: []
}

export const CMDX_CHAIN_INFO: ChainInfo = {
  rpc: env('CMDX_RPC', 'https://edge.stride.zone/api/comdex/rpc'),
  rest: env('CMDX_REST', 'https://edge.stride.zone/api/comdex/lcd'),
  chainId: env('CMDX_CHAIN_ID', 'comdex-1'),
  chainName: env('CMDX_CHAIN_NAME', 'Comdex'),
  bip44: {
    coinType: 118
  },
  bech32Config: Bech32Address.defaultBech32Config('comdex'),
  stakeCurrency: {
    coinDenom: 'CMDX',
    coinMinimalDenom: 'ucmdx',
    coinDecimals: 6,
    coinGeckoId: 'comdex'
    // @TODO: Keplr complains about this but not for other chains because the other chains
    // are already part of Keplr. We might need to fix this buy prepending the full stride
    // url.
    // coinImageUrl: '/chains/CMDX.svg'
  },
  currencies: [
    {
      coinDenom: 'CMDX',
      coinMinimalDenom: 'ucmdx',
      coinDecimals: 6,
      coinGeckoId: 'comdex'
      // @TODO: Keplr complains about this but not for other chains because the other chains
      // are already part of Keplr. We might need to fix this buy prepending the full stride
      // url.
      // coinImageUrl: '/chains/CMDX.svg'
    }
  ],
  feeCurrencies: [
    {
      coinDenom: 'CMDX',
      coinMinimalDenom: 'ucmdx',
      coinDecimals: 6,
      coinGeckoId: 'comdex'
      // @TODO: Keplr complains about this but not for other chains because the other chains
      // are already part of Keplr. We might need to fix this buy prepending the full stride
      // url.
      // coinImageUrl: '/chains/CMDX.svg'
    }
  ],
  features: []
}

export const SOMM_CHAIN_INFO: ChainInfo = {
  rpc: env('SOMM_RPC', 'https://edge.stride.zone/api/sommelier/rpc'),
  rest: env('SOMM_REST', 'https://edge.stride.zone/api/sommelier/lcd'),
  chainId: env('SOMM_CHAIN_ID', 'sommelier-3'),
  chainName: env('SOMM_CHAIN_NAME', 'Sommelier'),
  bip44: {
    coinType: 118
  },
  bech32Config: Bech32Address.defaultBech32Config('somm'),
  stakeCurrency: {
    coinDenom: 'SOMM',
    coinMinimalDenom: 'usomm',
    coinDecimals: 6,
    coinGeckoId: 'sommelier'
  },
  currencies: [
    {
      coinDenom: 'SOMM',
      coinMinimalDenom: 'usomm',
      coinDecimals: 6,
      coinGeckoId: 'sommelier'
    }
  ],
  feeCurrencies: [
    {
      coinDenom: 'SOMM',
      coinMinimalDenom: 'usomm',
      coinDecimals: 6,
      coinGeckoId: 'sommelier'
    }
  ],
  features: []
}

export const KUJI_CHAIN_INFO: ChainInfo = {
  rpc: 'https://kujira-rpc.polkachu.com:443',
  rest: 'https://kujira-api.polkachu.com:443',
  chainId: 'kaiyo-1',
  chainName: 'Kujira',
  bip44: {
    coinType: 118
  },
  bech32Config: Bech32Address.defaultBech32Config('kujira'),
  stakeCurrency: {
    coinDenom: 'KUJI',
    coinMinimalDenom: 'ukuji',
    coinDecimals: 6,
    coinGeckoId: 'kujira'
  },
  currencies: [
    {
      coinDenom: 'KUJI',
      coinMinimalDenom: 'ukuji',
      coinDecimals: 6,
      coinGeckoId: 'kujira'
    }
  ],
  feeCurrencies: [
    {
      coinDenom: 'KUJI',
      coinMinimalDenom: 'ukuji',
      coinDecimals: 6,
      coinGeckoId: 'kujira'
    }
  ],
  features: []
}

export const CARBON_CHAIN_INFO: ChainInfo = {
  rpc: 'https://tm-api.carbon.network:443',
  rest: 'https://api.carbon.network:443',
  chainId: 'carbon-1',
  chainName: 'Carbon',
  bip44: {
    coinType: 118
  },
  bech32Config: Bech32Address.defaultBech32Config('swth'),
  stakeCurrency: {
    coinDenom: 'SWTH',
    coinMinimalDenom: 'swth',
    coinDecimals: 6,
    coinGeckoId: 'carbon-protocol'
  },
  currencies: [
    {
      coinDenom: 'SWTH',
      coinMinimalDenom: 'swth',
      coinDecimals: 6,
      coinGeckoId: 'carbon-protocol'
    }
  ],
  feeCurrencies: [
    {
      coinDenom: 'SWTH',
      coinMinimalDenom: 'swth',
      coinDecimals: 6,
      coinGeckoId: 'carbon-protocol'
    }
  ],
  features: []
}

export const AGORIC_CHAIN_INFO: ChainInfo = {
  rpc: 'https://agoric-strd-rpc.polkachu.com',
  rest: 'https://agoric-strd-api.polkachu.com',
  chainId: 'agoric-3',
  chainName: 'Agoric',
  bip44: {
    coinType: 118
  },
  bech32Config: Bech32Address.defaultBech32Config('agoric'),
  stakeCurrency: {
    coinDenom: 'BLD',
    coinMinimalDenom: 'ubld',
    coinDecimals: 6,
    coinGeckoId: 'agoric'
  },
  currencies: [
    {
      coinDenom: 'BLD',
      coinMinimalDenom: 'ubld',
      coinDecimals: 6,
      coinGeckoId: 'agoric'
    }
  ],
  feeCurrencies: [
    {
      coinDenom: 'BLD',
      coinMinimalDenom: 'ubld',
      coinDecimals: 6,
      coinGeckoId: 'agoric'
    }
  ],
  features: []
}

export const DYDX_CHAIN_INFO: ChainInfo = {
  rpc: env('DYDX_RPC', 'https://edge.stride.zone/api/dydx/rpc'),
  rest: env('DYDX_REST', 'https://edge.stride.zone/api/dydx/lcd'),
  chainId: env('DYDX_CHAIN_ID', 'dydx-mainnet-1'),
  chainName: env('DYDX_CHAIN_NAME', 'DYDX'),
  bip44: {
    coinType: 118
  },
  bech32Config: Bech32Address.defaultBech32Config('dydx'),
  stakeCurrency: {
    coinDenom: 'DYDX',
    coinMinimalDenom: 'adydx',
    coinDecimals: 18,
    coinGeckoId: 'dydx-chain'
  },
  currencies: [
    {
      coinDenom: 'DYDX',
      coinMinimalDenom: 'adydx',
      coinDecimals: 18,
      coinGeckoId: 'dydx-chain'
    }
  ],
  feeCurrencies: [
    {
      coinDenom: 'DYDX',
      coinMinimalDenom: 'adydx',
      coinDecimals: 18,
      coinGeckoId: 'dydx-chain'
    }
  ],
  features: []
}

export const TIA_CHAIN_INFO: ChainInfo = {
  rpc: env('TIA_RPC', 'https://edge.stride.zone/api/celestia/rpc'),
  rest: env('TIA_REST', 'https://edge.stride.zone/api/celestia/lcd'),
  chainId: env('TIA_CHAIN_ID', 'celestia'),
  chainName: env('TIA_CHAIN_NAME', 'Celestia'),
  bip44: {
    coinType: 118
  },
  bech32Config: Bech32Address.defaultBech32Config('celestia'),
  stakeCurrency: {
    coinDenom: 'TIA',
    coinMinimalDenom: 'utia',
    coinDecimals: 6,
    coinGeckoId: 'celestia'
  },
  currencies: [
    {
      coinDenom: 'TIA',
      coinMinimalDenom: 'utia',
      coinDecimals: 6,
      coinGeckoId: 'celestia'
    }
  ],
  feeCurrencies: [
    {
      coinDenom: 'TIA',
      coinMinimalDenom: 'utia',
      coinDecimals: 6,
      coinGeckoId: 'celestia'
    }
  ],
  features: []
}

export const DYM_CHAIN_INFO: ChainInfo = {
  rpc: env('DYM_RPC', 'https://edge.stride.zone/api/dymension/rpc'),
  rest: env('DYM_REST', 'https://edge.stride.zone/api/dymension/lcd'),
  chainId: env('DYM_CHAIN_ID', 'dymension_1100-1'),
  chainName: env('DYM_CHAIN_NAME', 'Dymension'),
  bip44: {
    coinType: 60
  },
  bech32Config: Bech32Address.defaultBech32Config('dym'),
  stakeCurrency: {
    coinDenom: 'DYM',
    coinMinimalDenom: 'adym',
    coinDecimals: 18,
    coinGeckoId: 'dymension'
  },
  currencies: [
    {
      coinDenom: 'DYM',
      coinMinimalDenom: 'adym',
      coinDecimals: 18,
      coinGeckoId: 'dymension'
    }
  ],
  feeCurrencies: [
    {
      coinDenom: 'DYM',
      coinMinimalDenom: 'adym',
      coinDecimals: 18,
      coinGeckoId: 'dymension'
    }
  ],
  features: []
}

export const SAGA_CHAIN_INFO: ChainInfo = {
  rpc: env('SAGA_RPC', 'https://edge.stride.zone/api/saga/rpc'),
  rest: env('SAGA_REST', 'https://edge.stride.zone/api/saga/lcd'),
  chainId: env('SAGA_CHAIN_ID', 'ssc-1'),
  chainName: env('SAGA_CHAIN_NAME', 'Saga'),
  bip44: {
    coinType: 118
  },
  bech32Config: Bech32Address.defaultBech32Config('saga'),
  stakeCurrency: {
    coinDenom: 'SAGA',
    coinMinimalDenom: 'usaga',
    coinDecimals: 6,
    coinGeckoId: 'saga'
  },
  currencies: [
    {
      coinDenom: 'SAGA',
      coinMinimalDenom: 'usaga',
      coinDecimals: 6,
      coinGeckoId: 'saga'
    }
  ],
  feeCurrencies: [
    {
      coinDenom: 'SAGA',
      coinMinimalDenom: 'usaga',
      coinDecimals: 6,
      coinGeckoId: 'saga'
    }
  ],
  features: []
}

export const ISLM_CHAIN_INFO: ChainInfo = {
  rpc: env('ISLM_RPC', 'https://edge.stride.zone/api/haqq/rpc'),
  rest: env('ISLM_REST', 'https://edge.stride.zone/api/haqq/lcd'),
  chainId: env('ISLM_CHAIN_ID', 'haqq_11235-1'),
  chainName: env('ISLM_CHAIN_NAME', 'Haqq Network'),
  stakeCurrency: {
    coinDenom: 'ISLM',
    coinMinimalDenom: 'aISLM',
    coinDecimals: 18,
    coinGeckoId: 'islamic-coin'
  },
  bip44: {
    coinType: 60
  },
  bech32Config: Bech32Address.defaultBech32Config('haqq'),
  currencies: [
    {
      coinDenom: 'ISLM',
      coinMinimalDenom: 'aISLM',
      coinDecimals: 18,
      coinGeckoId: 'islamic-coin'
    }
  ],
  feeCurrencies: [
    {
      coinDenom: 'ISLM',
      coinMinimalDenom: 'aISLM',
      coinDecimals: 18,
      coinGeckoId: 'islamic-coin'
    }
  ],
  features: ['eth-address-gen', 'eth-key-sign']
}

export const BAND_CHAIN_INFO: ChainInfo = {
  rpc: env('BAND_RPC', 'https://edge.stride.zone/api/band/rpc'),
  rest: env('BAND_REST', 'https://edge.stride.zone/api/band/lcd'),
  chainId: env('BAND_CHAIN_ID', 'laozi-mainnet'),
  chainName: env('BAND_CHAIN_NAME', 'Band Protocol'),
  stakeCurrency: {
    coinDenom: 'BAND',
    coinMinimalDenom: 'uband',
    coinDecimals: 6,
    coinGeckoId: 'band-protocol'
  },
  bip44: {
    coinType: 118
  },
  bech32Config: Bech32Address.defaultBech32Config('band'),
  currencies: [
    {
      coinDenom: 'BAND',
      coinMinimalDenom: 'uband',
      coinDecimals: 6,
      coinGeckoId: 'band-protocol'
    }
  ],
  feeCurrencies: [
    {
      coinDenom: 'BAND',
      coinMinimalDenom: 'uband',
      coinDecimals: 6,
      coinGeckoId: 'band-protocol'
    }
  ],
  features: []
}

export const CHAIN_INFO_LIST: Record<SelectedCoinDenom, ChainInfo> = {
  ATOM: GAIA_CHAIN_INFO,
  OSMO: OSMO_CHAIN_INFO,
  STARS: STARS_CHAIN_INFO,
  JUNO: JUNO_CHAIN_INFO,
  LUNA: LUNA_CHAIN_INFO,
  EVMOS: EVMOS_CHAIN_INFO,
  SCRT: SCRT_CHAIN_INFO,
  STRD: STRIDE_CHAIN_INFO,
  INJ: INJ_CHAIN_INFO,
  UMEE: UMEE_CHAIN_INFO,
  CRE: CRE_CHAIN_INFO,
  CMDX: CMDX_CHAIN_INFO,
  IBCX: OSMO_CHAIN_INFO,
  KUJI: KUJI_CHAIN_INFO,
  USK: KUJI_CHAIN_INFO,
  IST: AGORIC_CHAIN_INFO,
  SWTH: CARBON_CHAIN_INFO,
  USC: CARBON_CHAIN_INFO,
  SOMM: SOMM_CHAIN_INFO,
  BLD: AGORIC_CHAIN_INFO,
  DYDX: DYDX_CHAIN_INFO,
  TIA: TIA_CHAIN_INFO,
  DYM: DYM_CHAIN_INFO,
  SAGA: SAGA_CHAIN_INFO,
  ISLM: ISLM_CHAIN_INFO,
  BAND: BAND_CHAIN_INFO
}

// This list order is also used by the CoinSelector as is
export const SUPPORTED_COIN_DENOMS = [
  'ATOM',
  'TIA',
  'DYDX',
  'OSMO',
  'STARS',
  'DYM',
  'SAGA',
  'JUNO',
  'LUNA',
  'EVMOS',
  'INJ',
  'UMEE',
  'CMDX',
  // IBCX team found errors after an Osmosis upgrade so we'll disable it for the time being
  // @see https://github.com/Stride-Labs/interface/issues/424
  // 'IBCX',
  'SOMM',
  'ISLM',
  'BAND'
] as const

export const SUPPORTED_STAKED_COIN_DENOMS = [
  'stATOM',
  'stTIA',
  'stDYDX',
  'stOSMO',
  'stSTARS',
  'stDYM',
  'stSAGA',
  'stJUNO',
  'stLUNA',
  'stEVMOS',
  'stINJ',
  'stUMEE',
  'stCMDX',
  // 'stIBCX',
  'stSOMM',
  'stISLM',
  'stBAND'
] as const

// For context: We are trying to narrow down our options for the recipient selection of Autopilot v2.
// Our criteria for the options are:
// - Stride
// - Host chain
// - Chains that we have integrations with - "basically analog to the earn page" as Lovis said. The chains below are the ones I found in the earn page.
export const SUPPORTED_COIN_DENOMS_AUTOPILOT_RECIPIENT = ['STRD', 'ATOM', 'OSMO', 'LUNA', 'INJ', 'EVMOS'] as const

export const SUPPORTED_CHAIN_INFO_LIST = SUPPORTED_COIN_DENOMS.map((denom) => {
  return CHAIN_INFO_LIST[denom]
})
